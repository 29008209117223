<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveRule" @reset="onReset">
              <div class="col-md-6">
                <b-form-group
                    id="input-group-1"
                    label="Loại(*):"
                    label-for="input-1"
                >
                  <b-form-select
                      id="input-1"
                      v-model="form.type"
                      required
                  >
                    <option value="">-Chọn-</option>
                    <option :value="1">HTML</option>
                    <option :value="2">TABLE</option>

                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                    id="input-group-1"
                    label="Content(*):"
                    label-for="input-2"
                    required
                >
                  <vue-editor required v-model="form.data"></vue-editor>

                </b-form-group>
              </div>
              <div class="col-md-12">
                <div class="form-group" v-if="form.type ==2">
                  <label for="input">Thông tin bảng</label>

                  <table class="table ">
                    <thead>
                    <tr style="font-size: 13px;">
                      <th scope="5" class="border-top-0">Label</th>
                      <th scope="5" class="border-top-0">giá trị </th>
                      <th scope="2" class="border-top-0">Tác vụ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in configs" :key="index">

                      <td>
                        <input class="form-control" v-model="item.label">
                      </td>
                      <td>
                        <input class="form-control" v-model="item.value">
                      </td>

                      <td>
                        <button type="button" class="btn btn-danger btn-sm " @click="removeRuleItem(index)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div  class="btn btn-sm btn-primary" @click="addRule">Thêm dòng</div>
                </div>

              </div>
              <div class="col-md-12">
                <div class="row">
                  <b-form-group
                      id="input-group-1"
                      label="Vị trí:"
                      label-for="input-1"
                      class="col-md-6"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="form.position"
                        required
                    />
                  </b-form-group>
                  <div class="form-inline col-md-6">
                    <input  id="show-bn-cms" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                    <label for="show-bn-cms"  style=" margin-left: 10px">Hiển thị </label>
                  </div>
                </div>
              </div>



              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
                <router-link v-if="is_edit" :to="'/campaign/rule'">
                  <b-button variant="danger">Hủy bỏ</b-button>
                </router-link>
                <b-button v-else type="reset" variant="danger">Làm mới</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import { VueEditor } from "vue2-editor";
import Vue from "vue";
Vue.component("multiselect", Multiselect);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormCategory",
  mixins: [Common],
  components: {
    VueEditor
  },
  data() {
    return {
      form: {
        id: '',
        type: '',
        status: 1,
        data: '',
        position: '',
        extra_data: '',

      },
      configs:[
        {
          label: '',
          value: '',
        }
      ],
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách thể lệ ", route: "/campaign/rule" },
      { title: this.is_edit ?"Sửa thể lệ" : "Thêm thể lệ" },
    ]);
  },
  methods: {
    removeRuleItem (index) {
      let that = this;
      this.configs.forEach((item, i) => {
        if (i == index) {
          that.configs.splice(i, 1);
        }
      });
    },
    addRule () {
      this.configs.push({
        label: '',
        value: '',
      });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.icon = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadRule(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getRule(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.form.status = body.data.status;
          this.form.data = body.data.data;
          this.form.position = body.data.position;
          this.form.type = body.data.type;
          if (body.data.extra_data){
            this.configs = JSON.parse(JSON.parse(body.data.extra_data));
          }


        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveRule: async function () {

      this.form.extra_data = JSON.stringify(this.configs);
      let params = this.form;
      if (this.is_edit) {
        this.updateRule(params);
        return false;
      }

      CampaignRepository.createRule(params)
        .then((response) => {
          this.$bus.$emit("show-`loading`", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_rule_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    updateRule: async function (params) {
      this.$bus.$emit("show-loading", true);

      CampaignRepository.updateRule(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "campaign_rule_challenges" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        name: "",
        status: 1,
        icon: '',
      }

    },
  },
  created() {
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadRule(this.$route.params.id);
    }
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
